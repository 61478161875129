import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// import MyAlertBanner from "./components/alertbanner";
import MyNavbar from "./components/navbar";
import MyFooter from "./components/footer";

import About from "./pages/about";
import Album from "./pages/album";
import Announce from "./pages/announce";
import Articles from "./pages/articles";
import ArticleRouter from "./pages/articleRouter";
import CateractInfo from "./pages/cataract";
import Contact from "./pages/contact";
import Schedule from "./pages/schedule";
import Services from "./pages/services";
import Home from "./pages/home";
import Profile from "./pages/profile";
import Traffic from "./pages/traffic";

class App extends React.Component {
  render() {
    return (
      <>
        <Router>
          <MyNavbar />
          {/* <MyAlertBanner /> */}
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/announce" element={<Announce />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/articles/:userId" element={<ArticleRouter />} />
            <Route path="/cataract" element={<CateractInfo />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/traffic" element={<Traffic />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/services" element={<Services />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/album" element={<Album />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
        <MyFooter />
      </>
    );
  }
}

export default App;
