import { Container, Image } from "react-bootstrap";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import livingImage from "../images/new_clinic/glasses.jpeg";

function Contact() {
  return (
    <>
      <div className=" banner">
        <h1 className="text-banner">聯絡我們</h1>
      </div>

      <Container>
        <div className="jumbotron">
          <MDBRow className="align-items-center row-cols-1 row-cols-md-2">
            <MDBCol className="d-flex flex-column">
              <p className="text-left text-mid">診所專線：02-26292616</p>
              <p className="text-left text-mid">
                加入本診所LINE好友：{" "}
                <a href="https://lin.ee/GqDFQ0E">
                  <Image
                    src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
                    alt="加入好友"
                    height="36"
                    border="0"
                  />
                </a>
              </p>
              <p className="text-left text-mid">
                診所信箱：doctor@everlighteye.com
              </p>
            </MDBCol>
            <MDBCol>
              <Image src={livingImage} fluid></Image>
            </MDBCol>
          </MDBRow>
        </div>
      </Container>
    </>
  );
}

export default Contact;
