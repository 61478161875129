import { Container, Row, Col, Image } from "react-bootstrap";

import CardsSection from "../components/cards";
import { FirstMessage } from "../components/dashboard";
import MapSection from "../components/map";

import homeImage from "../images/new_clinic/clinic_front.jpeg";
import MapImage from "../images/mapicon.jpg";
import smalllogo from "../images/tip.jpg";

const location = {
  address: "新北市淡水區中正東路二段75號",
  lat: 25.1543327029596,
  lng: 121.45966102696498,
};

function Home() {
  return (
    <>
      <Container>
        <Row className="row-cols-1 row-cols-sm-2 align-items-center">
          <Col>
            <Image src={homeImage} alt="homepage" fluid />
          </Col>
          <Col>
            {/* <h1 className="text-title kai">永光眼科診所</h1>
            <h1 className="text-mid">EVERLIGHT EYE CLINIC</h1> */}
            <div className="bordertron square  mt-3">
              <h2 className="text-left">最新消息</h2>
              <hr />
            </div>
            <FirstMessage />
          </Col>
        </Row>

        {/* <Container className="float">
          <h1 className="text-title">永光眼科診所</h1>
          <h1 className="text-mid">EVERLIGHT EYE CLINIC</h1>
        </Container> */}
      </Container>
      <Container>
        <div className="jumbotron">
          <h2 className="text-center">交通資訊</h2>
          <hr />
          <p className="text-mid text-center">新北市淡水區中正東路二段75號</p>
          <p className="text-mid text-center">捷運紅樹林站正對面</p>
          <p className="text-mid text-center">永光眼科診所電話:02-26292616</p>
          <Row className="align-items-center row-cols-1 row-cols-md-2">
            <Col>
              <MapSection location={location} zoomLevel={17} />
            </Col>
            <Col>
              <Image src={MapImage} alt="directions" fluid></Image>
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <div className="jumbotron">
          <h2 className="text-center">診所資訊</h2>
          <hr />
          <p className="text-mid">
            永光眼科診所（原淡水日聖眼科診所），由沈詩詠院長於民國87年9月（1998年9月）設立。
            進行白內障手術已有二十多年及數千例的經驗。
          </p>
        </div>
      </Container>
      <CardsSection />
    </>
  );
}

export default Home;
