import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Container, Image } from "react-bootstrap";
import MapSection from "../components/map";

import MapImage from "../images/mapicon.jpg";

const location = {
  address: "新北市淡水區中正東路二段75號",
  lat: 25.1543327029596,
  lng: 121.45966102696498,
};

function Traffic() {
  return (
    <>
      <div className="banner">
        <h1 className="text-banner">交通資訊</h1>
      </div>
      <Container>
        <MDBRow className="row-cols-1 row-cols-md-2">
          <MDBCol className="d-flex">
            <div className="text-mid">
              <div className="smalltron">
                <h2>永光眼科診所地址</h2>
                <hr />
                <p>新北市淡水區中正東路二段75號</p>
                <p>
                  捷運<span className="text-highlight">紅樹林站正對面</span>
                </p>
                <p>永光眼科診所電話: 02-26292616</p>
              </div>
              <div className="smalltron">
                <h2>
                  <i className="fas fa-car" /> 附近停車場
                </h2>
                <hr />
                {/* <p className="text-highlight">
                  <i className="fas fa-parking" /> CITY PARKING
                  城市車旅停車場（淡水學府站停車場）
                </p>
                <p className="font-weight-light">
                  新北市淡水區中正東路&學府路交叉口
                </p>
                <p className="text-highlight">
                  <i className="fas fa-parking" /> 學府停車場
                </p>
                <p>新北市淡水區鄧公路2巷6號</p> */}
                <p className="text-highlight">
                  <i className="fas fa-parking" /> 捷運紅樹林站停車場
                </p>
              </div>
              <div className="smalltron">
                <h2>
                  <i className="fas fa-subway" /> 捷運
                </h2>
                <hr />
                <p className="font-weight-light">
                  從紅樹林站<span className="text-highlight"></span>
                  出站後<span className="text-highlight"></span>
                  ，過中正東路即到 (步行約1分鐘)
                </p>
              </div>
              <div className="smalltron">
                <h2>
                  <i class="fas fa-bus-alt" /> 公車
                </h2>
                <hr />
                <p className="font-weight-light">
                  搭公車至<span className="text-highlight">捷運紅樹林站</span>
                </p>
              </div>
            </div>
          </MDBCol>
          <MDBCol>
            <div className="jumbotron">
              <MapSection location={location} zoomLevel={17} />
            </div>
            <Container>
              <Image src={MapImage} alt="directions" fluid></Image>
            </Container>
          </MDBCol>
        </MDBRow>
      </Container>
    </>
  );
}

export default Traffic;
