import { Container, Image } from "react-bootstrap";
import { MDBFooter, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";

function Footer() {
  return (
    <MDBFooter
      bgColor="dark"
      color="white"
      className="text-center text-lg-start"
    >
      {/* <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div>
          <a
            href="https://www.facebook.com/everlighteye/"
            className="me-4 text-reset"
          >
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="twitter" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="google" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="instagram" />
          </a>
        </div>
      </section> */}

      <section className="d-flex">
        <Container className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h4 className="text-uppercase fw-bold mb-4">
                <MDBIcon icon="eye" className="me-3" />
                永光眼科診所
              </h4>
              <h4 className="text-uppercase fw-bold mb-4">
                everlight eye clinic
              </h4>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">診療項目</h6>
              <p className="text-reset">白內障手術</p>
              <p className="text-reset">角膜塑型術</p>
              <p className="text-reset">光學斷層掃描</p>
              <p className="text-reset">青光眼雷射治療</p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4 text-center">
              <h6 className="text-uppercase fw-bold mb-4">
                加入本診所line好友
              </h6>
              <div className="d-flex flex-column align-items-center">
                <a href="https://lin.ee/GqDFQ0E">
                  <Image
                    src="https://qr-official.line.me/sid/L/326ejlkz.png?shortenUrl=true"
                    fluid
                    style={{ maxWidth: "150px" }}
                  />
                </a>
                <a href="https://lin.ee/GqDFQ0E">
                  <Image
                    src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
                    alt="加入好友"
                    height="36"
                    border="0"
                  />
                </a>
              </div>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">聯絡方式</h6>
              <p>
                <MDBIcon icon="home" className="me-3" />
                新北市淡水區中正東路二段75號
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                doctor@everlighteye.com
              </p>
              <p>
                <a
                  href="https://www.facebook.com/everlighteye/"
                  className="me-4 text-reset"
                >
                  <MDBIcon fab icon="facebook-f" />
                </a>
                永光眼科診所-原淡水日聖眼科診所
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> 02-26292616
              </p>
            </MDBCol>
          </MDBRow>
        </Container>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2022 Copyright:
        <a className="text-reset fw-bold" href="https://everlighteye.com/">
          everlighteye.com
        </a>
      </div>
    </MDBFooter>
  );
}

export default Footer;
