import { Container, Image } from "react-bootstrap";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import surgeonImage from "../images/new_clinic/surgeon.jpeg";

function Profile() {
  return (
    <>
      <div className="banner">
        <h1 className="text-banner">主治醫師</h1>
      </div>
      <Container className="">
        <div className="jumbotron text-left text-mid">
          <MDBRow className="align-items-center row-cols-1 row-cols-lg-2">
            <MDBCol className="d-flex flex-column">
              <h2>沈詩詠 院長</h2>
              <hr />
              <ul className="list-unstyled text-mid">
                <li>北一女中畢業</li>
                <li>台大醫學系畢業</li>
                <li>台大醫院眼科部主治醫師</li>
                <li> 中華民國眼科專科醫師</li>
                <li> 中華民國眼科醫學會會員</li>
                <li>台灣白內障及屈光手術醫學會會員</li>
                <li>中華民國超音波醫學會會員</li>
                <li>國際眼科醫學會考試合格 </li>
                <li>美國國家醫師考試合格</li>
                <li> 美國眼科醫學會會員</li>
                <li>教育部核定學童視力保健種子醫師 </li>
                <li>驗光人員種子講師</li>
                <li> 近視病防治醫師</li>
                <li> 家庭醫師訓練合格 </li>
                <li>淡水日聖眼科診所院長 </li>
                <li>現任永光眼科診所院長</li>
              </ul>
            </MDBCol>
            <MDBCol>
              <Image src={surgeonImage} fluid rounded />
            </MDBCol>
          </MDBRow>
        </div>
      </Container>
    </>
  );
}

export default Profile;
