import React from "react";
import Blogboard from "../components/blogboard";

const Content = () => {
  return (
    <>
      <div className="banner">
        <h1 className="text-banner">保健知識</h1>
      </div>

      <Blogboard />
    </>
  );
};

export default Content;
